import { SettingsDrawer } from '@/components';
import { useAppContext } from '@/context/AppContext';
import { defaultSettings } from '@/context/settings-config';
import Layout from '@/layout';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Outlet } from 'react-router';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import { SplashScreen } from './components/loading-screen';

export default function App() {
  const { getIdTokenClaims, logout } = useAuth0();
  const { isLoadingAuthContext } = useAppContext();

  useEffect(() => {
    const checkToken = async () => {
      const claims = await getIdTokenClaims();
      if (claims?.exp) {
        const currentTime = Math.floor(Date.now() / 1000);
        if (claims.exp < currentTime) {
          logout({ logoutParams: { returnTo: window.location.origin } });
        }
      }
    };
    checkToken();
  }, [getIdTokenClaims, logout]);

  if (isLoadingAuthContext.value) {
    return <SplashScreen />;
  }

  return (
    <Layout>
      <Container maxWidth="xl">
        <Stack spacing={2}>
          <SettingsDrawer defaultSettings={defaultSettings} />
          <Outlet />
        </Stack>
      </Container>
    </Layout>
  );
}
