import 'simplebar-react/dist/simplebar.min.css';

import { SnackbarProvider } from '@/components/snackbar/';
import { ContextProvider } from '@/context/AppContext';
import { AuthProvider } from '@/context/auth';
import { SettingsProvider } from '@/context/settings-provider';
import { themeConfig, ThemeProvider } from '@/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, useRoutes } from 'react-router';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { defaultSettings } from './context/settings-config';
import { AppRoutes } from './routes';

const queryClient = new QueryClient();

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

const Router = () => useRoutes(AppRoutes);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SettingsProvider defaultSettings={defaultSettings}>
            <ThemeProvider
              noSsr
              defaultMode={themeConfig.defaultMode}
              modeStorageKey={themeConfig.modeStorageKey}
            >
              <SnackbarProvider>
                <ContextProvider>
                  <AuthProvider>
                    <Router />
                  </AuthProvider>
                </ContextProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </HelmetProvider>
);
