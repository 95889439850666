import { CustomErrorBoundary } from '@/components/ErrorBoundary';
import { SplashScreen } from '@/components/loading-screen';
import RoleGuard from '@/context/auth/RoleGuard';
import { CustomerType } from '@/enums';
import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router';

import App from '../App';

const Home = lazy(() => import('../pages/Home'));
const Orders = lazy(() => import('../pages/orders/Orders'));
const Lenders = lazy(() => import('../pages/lenders'));
const CreateOrder = lazy(() => import('../pages/orders/OrderWizard'));
const Vendors = lazy(() => import('../pages/Vendors'));
const Reports = lazy(() => import('../pages/Reports'));
const NotFound = lazy(() => import('../pages/NotFound'));
const OrderDetails = lazy(() => import('../pages/orders/OrderDetails'));
const Forbidden = lazy(() => import('../pages/Forbidden'));
const LenderDetails = lazy(() => import('../pages/lenders/details/LenderDetails'));
const BranchDetails = lazy(() => import('../pages/branches/BranchDetails'));
const VendorDetails = lazy(() => import('../pages/vendors/VendorDetails'));
const ManageVendorProduct = lazy(() => import('../pages/vendors/product'));

export const AppRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <CustomErrorBoundary>
          <App />
        </CustomErrorBoundary>
      </Suspense>
    ),
    children: [
      { path: '/', element: <Home />, index: true },
      { path: 'orders', element: <Orders /> },
      { path: 'orders/create', element: <CreateOrder /> },
      { path: 'orders/:id', element: <OrderDetails /> },
      {
        path: 'lenders',
        element: (
          <RoleGuard redirect="/forbidden" role={CustomerType.admin}>
            <Lenders />
          </RoleGuard>
        ),
      },
      {
        path: 'lenders/:lenderOrgCodeParam',
        element: (
          <RoleGuard redirect="/forbidden" role={CustomerType.admin}>
            <LenderDetails />
          </RoleGuard>
        ),
      },
      {
        path: 'lenders/:lenderOrgCodeParam/branch/:branchOrgCodeParam',
        element: (
          <RoleGuard redirect="/forbidden" role={CustomerType.admin}>
            <BranchDetails />
          </RoleGuard>
        ),
      },
      {
        path: 'vendors',
        element:
          import.meta.env.VITE_VIEW_VENDORS === 'true' ? (
            <RoleGuard redirect="/forbidden" role={CustomerType.admin}>
              <Vendors />
            </RoleGuard>
          ) : (
            <NotFound />
          ),
      },
      {
        path: 'vendors/:organizationCode',
        element:
          import.meta.env.VITE_VIEW_VENDORS === 'true' ? (
            <RoleGuard redirect="/forbidden" role={CustomerType.admin}>
              <VendorDetails />
            </RoleGuard>
          ) : (
            <NotFound />
          ),
      },
      {
        path: 'vendors/:organizationCode/product/:productCode',
        element:
          import.meta.env.VITE_VIEW_VENDORS === 'true' ? (
            <RoleGuard redirect="/forbidden" role={CustomerType.admin}>
              <ManageVendorProduct />
            </RoleGuard>
          ) : (
            <NotFound />
          ),
      },
      {
        path: 'reports',
        element: (
          <RoleGuard redirect="/forbidden" role={CustomerType.admin}>
            <Reports />
          </RoleGuard>
        ),
      },
      { path: '*', element: <NotFound /> },
    ],
  },
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [{ path: 'forbidden', element: <Forbidden /> }],
  },
];
