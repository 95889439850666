import type { LinkProps} from 'react-router';

import { forwardRef } from 'react';
import { Link as ReactRouterLink } from 'react-router';

interface RouterLinkProps extends Omit<LinkProps, 'to'> {
  href: string;
}

const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(({ href, ...other }, ref) => (
  <ReactRouterLink ref={ref} to={href} {...other} />
));

export default RouterLink;
