import type { IconProps } from '@iconify/react';

import { createClasses } from '@/theme/create-classes';
import { Icon } from '@iconify/react';
import { mergeClasses } from 'minimal-shared/utils';
import { forwardRef } from 'react';

import { styled } from '@mui/material/styles';

const iconifyClasses = {
  root: createClasses('iconify__root'),
};

export type IconifyProps = React.ComponentProps<typeof IconRoot> & IconProps;

export const Iconify = forwardRef<SVGSVGElement, IconifyProps>((props, ref) => {
  const { className, width = 20, sx, ...other } = props;

  return (
    <IconRoot
      ssr
      ref={ref}
      className={mergeClasses([iconifyClasses.root, className])}
      sx={[
        {
          width,
          height: width,
          flexShrink: 0,
          display: 'inline-flex',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...other}
    />
  );
});

export default Iconify;

const IconRoot = styled(Icon)``;
