import type { IconButtonProps } from '@mui/material/IconButton';

import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

import Iconify from '../../components/Iconify';
import { NAV } from '../../constants';
import { useSettingsContext } from '../../context/use-settings-context';
import { useResponsive } from '../../hooks/useResponsive';

export default function NavToggleButton({ sx, ...rest }: IconButtonProps) {
  const theme = useTheme();

  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  if (!lgUp) {
    return null;
  }

  return (
    <IconButton
      size="small"
      onClick={() =>
        settings.setField(
          'navLayout',
          settings.state.navLayout === 'vertical' ? 'mini' : 'vertical'
        )
      }
      sx={{
        p: 0.5,
        top: 32,
        position: 'fixed',
        left: NAV.W_VERTICAL - 12,
        zIndex: theme.zIndex.appBar + 1,
        transition: theme.transitions.create(['left'], {
          easing: 'var(--layout-transition-easing)',
          duration: 'var(--layout-transition-duration)',
        }),
        '&:hover': {
          color: 'text.primary',
          bgcolor: 'background.neutral',
        },
        ...sx,
      }}
      {...rest}
    >
      <Iconify
        width={16}
        icon={
          settings.state.navLayout === 'vertical'
            ? 'eva:arrow-ios-back-fill'
            : 'eva:arrow-ios-forward-fill'
        }
      />
    </IconButton>
  );
}
