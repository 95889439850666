import { varAlpha } from 'minimal-shared/utils';

import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

import { SvgColor } from '../components';
import AccountPopover from '../components/settings/AccountPopover';
import SettingsButton from '../components/settings/SettingsButton';
import { HEADER, NAV } from '../constants';
import { useSettingsContext } from '../context/use-settings-context';
import { useResponsive } from '../hooks/useResponsive';
import AuthContextMenu from './AuthContextMenu';

export default function Header({ onOpenNav }: { onOpenNav: VoidFunction }) {
  const settings = useSettingsContext();

  const isNavMini = settings.state.navLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const renderContent = (
    <>
      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic-menu-item.svg" />
        </IconButton>
      )}

      <AuthContextMenu />

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <SettingsButton />

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={[
        (theme) => ({
          height: HEADER.H_MOBILE,
          zIndex: theme.zIndex.appBar + 1,

          transition: theme.transitions.create(['height'], {
            duration: theme.transitions.duration.shorter,
          }),
          ...theme.mixins.bgBlur({
            color: varAlpha(theme.vars.palette.background.default, 0.8),
            blur: 6,
          }),
          ...(lgUp && {
            width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
            height: HEADER.H_DESKTOP,
            ...(isNavMini && {
              width: `calc(100% - ${NAV.W_MINI + 1}px)`,
            }),
          }),
        }),
      ]}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
