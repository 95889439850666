import type { IconButtonProps } from '@mui/material/IconButton';

import { useSettingsContext } from '@/context/use-settings-context';

import IconButton from '@mui/material/IconButton';

import Iconify from '../Iconify';

export default function SettingsButton({ sx, ...other }: IconButtonProps) {
  const settings = useSettingsContext();

  return (
    <IconButton
      aria-label="settings"
      onClick={settings.onToggleDrawer}
      sx={[{ p: 0, width: 40, height: 40 }, ...(Array.isArray(sx) ? sx : [sx])]}
      {...other}
    >
      <Iconify icon="solar:settings-bold-duotone" width={24} />
    </IconButton>
  );
}
