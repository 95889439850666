import type { IFormStep } from '@/types';

import React, { useState } from 'react';

import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

export default function Wizard({ steps }: { steps: IFormStep[] }) {
  const [completedSteps, setCompletedSteps] = useState<boolean[]>(Array(steps.length));
  const [activeStep, setActiveStep] = useState<number>(0);

  const handleStepClick = (index: number) => {
    const completedStepIndex = completedSteps.lastIndexOf(true);
    if (index <= completedStepIndex + 1) {
      setActiveStep(index);
    }
  };

  const handleContinue = () => {
    setCompletedSteps((prev) => {
      const newCompletedSteps = [...prev];
      newCompletedSteps[activeStep] = true;
      return newCompletedSteps;
    });
    if (activeStep < steps.length - 1) {
      setActiveStep((prev) => prev + 1);
    }
  };

  return (
    <>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{ width: '100%', maxWidth: 'lg', margin: 'auto' }}
      >
        {steps.map((step, index) => (
          <Step key={step.label} completed={completedSteps[index]}>
            <StepButton
              onClick={() => handleStepClick(index)}
              disabled={index > completedSteps.lastIndexOf(true) + 1}
              sx={{
                '& .MuiTouchRipple-root': { display: 'none' },
              }}
            >
              <StepLabel>{step.label}</StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>

      {React.createElement(steps[activeStep]?.component, {
        onContinue: handleContinue,
      })}
    </>
  );
}
