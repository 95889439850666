import type { SnackbarKey } from 'notistack';

import { closeSnackbar, SnackbarProvider as NotistackProvider } from 'notistack';
import { useRef } from 'react';

import IconButton from '@mui/material/IconButton';

import Iconify from '../Iconify';
import { StyledIcon, StyledNotistack } from './styles';

const SnackbarAction = ({ snackbarId }: { snackbarId: SnackbarKey }) => (
  <IconButton size="small" onClick={() => closeSnackbar(snackbarId)} sx={{ p: 0.5 }}>
    <Iconify width={16} icon="mingcute:close-line" />
  </IconButton>
);

const SnackbarIcons = {
  info: (
    <StyledIcon color="info">
      <Iconify icon="eva:info-fill" width={24} />
    </StyledIcon>
  ),
  success: (
    <StyledIcon color="success">
      <Iconify icon="eva:checkmark-circle-2-fill" width={24} />
    </StyledIcon>
  ),
  warning: (
    <StyledIcon color="warning">
      <Iconify icon="eva:alert-triangle-fill" width={24} />
    </StyledIcon>
  ),
  error: (
    <StyledIcon color="error">
      <Iconify icon="solar:danger-bold" width={24} />
    </StyledIcon>
  ),
};

export default function SnackbarProvider({ children }: { children: React.ReactNode }) {
  const notistackRef = useRef<any>(null);

  return (
    <NotistackProvider
      ref={notistackRef}
      maxSnack={5}
      preventDuplicate
      autoHideDuration={3000}
      variant="success" // Set default variant
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      iconVariant={SnackbarIcons}
      Components={{
        default: StyledNotistack,
        info: StyledNotistack,
        success: StyledNotistack,
        warning: StyledNotistack,
        error: StyledNotistack,
      }}
      // with close as default
      action={(snackbarId) => <SnackbarAction snackbarId={snackbarId} />}
    >
      {children}
    </NotistackProvider>
  );
}
